import { useState } from 'react'
import emailjs from '@emailjs/browser';

const initialState = {
  user_name: '',
  user_email: '',
  user_subject: '',
  message: '',
}
export const Contact = (props) => {
  const [{ user_name, user_email, user_subject,  message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { user_name, value } = e.target
    setState((prevState) => ({ ...prevState, [user_name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(user_name, user_email, user_subject, message)
    emailjs
      .sendForm(
        'service_ilv996f', 'template_9qoz77l', e.target, 'ktyh2xPxV5WN_GeJQ'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          // Clear all input values in form
          e.target.reset();
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
               <p>In your email please provide:</p>
               <li>Who you are (Your names so we know who we are talking to)</li>
               <li>Where you live (Sorry, we will not be able to ship our puppies - they are not cargo)</li>
               <li>What makes you a good Fur-Parent (Getting a feel for they type of parents you would be for them)</li>
               <li>What Gender of puppy you wish to get (Reminder - we are having only 2 this litter - we don't know the sex just yet)</li>
               <li>How to contact you (phone, email, etc)</li>
               <p>We will place you on the List - once our puppies are born...priority goes to whoever submitted first and is approved.</p>
               <p>We will contact you regarding approval process!  (We love our puppies and only want the best homes for them)</p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='user_name'
                        name='user_name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='user_email'
                        name='user_email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                      <input
                        type='text'
                        id='user_subject'
                        name='user_subject'
                        className='form-control'
                        placeholder='Subject'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Contact Us
                </button>
              </form>
              
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Design by{' '}
            <a href='https://www.argistech.com' rel='nofollow'>
              ARGIS Tech LLC
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
